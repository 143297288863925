"use client";
import { useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { toast } from "sonner";

const Subscribe = () => {
  const [state, handleSubmit] = useForm("xbjnopbl");

  useEffect(() => {
    if (state.succeeded) {
      toast.success("Obrigado por se inscrever!");
    }

    if (state.errors) {
      toast.error(
        "Ocorreu um erro ao enviar o formulário. Por favor, tente novamente mais tarde."
      );
    }
  }, [state]);

  if (state.succeeded) {
    return <p className="text-white font-bold">Obrigado por se inscrever!</p>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mailchimp-widget mb-4 mb-lg-5">
        {/*<p className="title text-white mb20">Newsletter MBRAS</p>*/}
        <div className="mailchimp-style1">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Seu melhor e-mail"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <button
            type="submit"
            className="w-32 h-full top-0 right-0 -mr-6 rounded-xl hover:bg-[#4D7079]"
          >
            Assinar
          </button>
        </div>
      </div>
    </form>
  );
};

export default Subscribe;
