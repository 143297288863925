import React from "react";
import { useTranslations } from "next-intl";
//import Image from "next/image";

const AddressWidget = () => {
  const t = useTranslations("home.footer.blocks.block6");

  const appList = [
    {
      icon: "fab fa-apple fz30 text-white",
      text: t("apps.ios.preTitle"),
      title: t("apps.ios.title"),
      link: "#",
    },
    {
      icon: "fab fa-google-play fz30 text-white",
      text: t("apps.android.preTitle"),
      title: t("apps.android.title"),
      link: "#",
    },
  ];

  return (
    <div>
      <p className="title text-white text-lg mb10">
        Cidade Jardim Corporate Center
      </p>
      <div className="row">
        <div className="col-auto text-white">
          <a
            href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
            aria-label="Cidade Jardim Corporate Center"
          >
            {/*<p className="text-sm text-gray-300 hover:text-gray-300">
              Cidade Jardim Corporate Center
  </p>*/}
          </a>

          <div className="mt-2">
            <a
              href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
              className="block text-white hover:text-gray-300 py-1"
              aria-label="Av. Magalhães de Castro, 4.800"
            >
              Av. Magalhães de Castro, 4.800
            </a>
            <div>
              <a
                href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
                className="block text-white hover:text-gray-300 py-1"
                aria-label="Park Tower - 23° andar"
              >
                Park Tower – 23° andar
              </a>
            </div>
            <a
              href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
              className="block text-white hover:text-gray-300 py-1"
              aria-label="Cidade Jardim - São Paulo - SP"
            >
              Cidade Jardim - São Paulo - SP
            </a>
            <div>
              <a
                href="https://maps.app.goo.gl/8FqHgfei2yJPbiKr7"
                className="block text-white hover:text-gray-300 py-1"
                aria-label="CEP: 05676-120"
              >
                CEP: 05676-120
              </a>
            </div>
          </div>
          {/*
          <Image
            src="https://res.cloudinary.com/mbras/image/upload/q_auto/f_auto/w_auto/dpr_auto/v1696840878/mbras/MBRAS_horizontal_B_rj6ej0.png"
            alt="MBRAS Logo"
            width={100}
            height={42}
            priority
            className="mt-8 mb-2"
          />
          <div>
            <p className="small_text text-sm">Referência em Altíssimo Padrão</p>
  </div>*/}
        </div>
      </div>
    </div>
  );
};

export default AddressWidget;
