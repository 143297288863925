"use client";

import Image from "next/image";
import Link from "next/link";
import AddressWidget from "./AddressWidget";
import Social from "./Social";
import Subscribe from "./Subscribe";
import MenuWidget from "./MenuWidget";
import Copyright from "./Copyright";
import { FaWhatsapp } from "react-icons/fa";
import { TbPhone } from "react-icons/tb";
import { MdOutlineMailOutline } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <div className="container mx-auto px-4">
        <div className="row flex flex-wrap -mx-4">
          <div className="col-lg-4 px-4">
            <Link className="" href="/" aria-label="Home">
              <Image
                width={125}
                height={55}
                className="mb40"
                src="/images/mbras-hb.png"
                alt="MBRAS Logo"
              />
            </Link>
            <AddressWidget />

            {/* Contato e Redes Sociais now side by side */}
            <div className="flex flex-col lg:flex-row justify-between">
              {/* Contato */}
              <div className="text-gray-200 my-4 lg:my-0 lg:mr-4">
                <p className="text-white text-lg mb-3">Contato</p>
                {/* Phone Contact */}
                <div className="flex items-center mb-3">
                  <FaWhatsapp className="text-white" size={24} />
                  <a
                    href="https://api.whatsapp.com/send?phone=5511977998888"
                    aria-label="+55 11 97799 8888"
                    className="px-2 hover:text-green-300 text-white"
                  >
                    +55 11 97799 8888
                  </a>
                </div>
                {/* Landline Phone Contact */}
                <div className="flex items-center mb-3">
                  <TbPhone className="text-white" size={24} />
                  <a
                    href="tel:+551151856999"
                    aria-label="+55 11 5185 6999"
                    className="px-2 hover:text-blue-300 text-white"
                  >
                    +55 11 5185 6999
                  </a>
                </div>
                {/* Email Contact */}
                <div className="flex items-center mb-3">
                  <MdOutlineMailOutline className="text-white" size={24} />
                  <a
                    href="mailto:mbras@mbras.com.br"
                    aria-label="mbras@mbras.com.br"
                    className="px-2 hover:text-gray-300 text-white"
                  >
                    mbras@mbras.com.br
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* End .col-lg-5 */}

          <div className="col-lg-8 px-4">
            <div className="footer-widget mb-4">
              {/* Redes Sociais and Subscribe Side by Side */}
              <div className="flex flex-col lg:flex-row justify-between">
                <div className="text-gray-200 mb-4 lg:mb-0 lg:flex-1">
                  <p className="text-white text-lg mb-3">Redes Sociais</p>
                  <Social />
                </div>
                <div className="lg:flex-1">
                  <p className="text-white text-lg mb-3">Newsletter</p>
                  <Subscribe />
                </div>
              </div>
              <div className="row justify-content-between">
                <MenuWidget />
              </div>
            </div>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}

      <Copyright />
    </>
  );
};

export default Footer;
