import { useEffect, useState } from "react";
import { client } from "@/lib/client";

const MenuWidget = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const fetchedPosts = await client.fetch(
        `*[_type == "post"] | order(date desc) [0...6]`
      );
      setPosts(fetchedPosts);
    };

    fetchPosts();
  }, []);

  const menuSections = [
    {
      title: "Publicações em Destaque",
      links: posts.map((post) => ({
        label: post.title,
        href: `https://blog.mbras.com.br/posts/${post.slug.current}`,
      })),
    },
    {
      title: "Links rápidos",
      links: [
        {
          label: "Anuncie seu imóvel",
          href: "/anuncie",
        },
        { label: "Gestão de Imóveis", href: "/gestao" },
        { label: "Sobre a MBRAS", href: "/sobre" },
        { label: "MBRAS Club", href: "/club" },
        {
          label: "Formulário de Contato",
          href: "/contato",
        },
        {
          label: "Política de Privacidade",
          href: "/privacy-policy",
        },
        {
          label: "Trabalhe Conosco",
          href: "https://www.linkedin.com/company/mbras-solu%C3%A7%C3%B5es-imobili%C3%A1rias/jobs/",
        },
      ],
    },
    {
      title: "Descubra",
      links: [
        {
          label: "Fazenda Boa Vista",
          href: "/fast?type=sale&locations=Fazenda Boa Vista",
        },
        {
          label: "Jardim Europa",
          href: "/fast?type=sale&locations=Jardim Europa",
        },
        {
          label: "Vila Nova Conceição",
          href: "/fast?type=sale&locations=Vila Nova Conceição",
        },
        { label: "Itaim Bibi", href: "/fast?type=sale&locations=Itaim Bibi" },
        {
          label: "Cidade Jardim",
          href: "/fast?type=sale&locations=Cidade Jardim",
        },
        { label: "Faria Lima", href: "/fast?type=sale&locations=Faria Lima" },
      ],
    },
  ];

  return (
    <span className="grid grid-cols-1 sm:gap-2 md:grid-cols-[20rem_1fr_1fr] md:gap-[3.75rem]">
      {menuSections.map((section, index) => (
        <div className="col-auto" key={index}>
          <div className="link-style1 mb-3">
            <p className="text-white mb25">{section.title}</p>
            <ul className="ps-0">
              {section.links.map((link, linkIndex) => (
                <li className="" key={linkIndex}>
                  <a className="truncate" href={link.href}>
                    {" "}
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </span>
  );
};

export default MenuWidget;
