import React from "react";

const Social = () => {
  const socialLinks = [
    {
      icon: "fab fa-facebook-f",
      url: "https://www.facebook.com/mbrasempreendimentos",
      name: "Facebook",
    },
    {
      icon: "fab fa-x-twitter",
      url: "https://www.twitter.com/mbrasimoveis",
      name: "Twitter",
    },
    {
      icon: "fab fa-instagram",
      url: "https://www.instagram.com/mbrasempreendimentos",
      name: "Instagram",
    },
    {
      icon: "fab fa-linkedin-in",
      url: "https://www.linkedin.com/company/mbras-solu%C3%A7%C3%B5es-imobili%C3%A1rias/",
      name: "LinkedIn",
    },
  ];

  const iconSizeClass = "text-3xl"; // Example size, adjust as needed

  return (
    <div className="social-style1 flex space-x-4">
      {socialLinks.map((social, index) => (
        <a
          key={index}
          href={social.url}
          className="hover:text-gray-600"
          target="_blank"
          rel="noopener noreferrer"
          aria-label={`Visite nossa página no ${social.name}`}
        >
          <i
            className={`${social.icon} ${iconSizeClass} list-inline-item`}
            aria-hidden="true"
          />
          <span className="sr-only">{social.name}</span>
        </a>
      ))}
    </div>
  );
};

export default Social;
